




























































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import Navbar from "@/components/common/Navbar.vue";
import Illustration from "@/components/common/Illustration.vue";
import Snackbar from "@/components/common/Snackbar.vue";
import GamificationService from "@/services/GamificationService";
import ActionablePopup from "@/components/common/Popup/ActionablePopup.vue";
import DataTable from "@/components/common/Tables/DataTable.vue";
import TextButton from "@/components/common/Buttons/TextButton.vue";
import { IGameList } from "@/interfaces/gamification";
import { ILink, ITableHeader } from "@/interfaces/common";
import { VLayout } from "vuetify/lib";
import ConfugurationService from "@/services/ConfigurationServices";
@Component({
  components: {
    Illustration,
    Navbar,
    Snackbar,
    ActionablePopup,
    DataTable,
    TextButton,
  },
})
export default class Create extends Vue {
  // for illustration
  private description: string = "No Games Found";
  private subdescription: string = "Please create a game to begin with";
  private valid: boolean = false;

  private timeout!: NodeJS.Timeout;
  private message: string = "";
  private snackbar: boolean = false;
  private snackbarColor: string = "success";
  private showGamesList: boolean = true;
  //flags for create or edit check
  private createGame: boolean = true;
  private menuItems: Array<ILink> = [];
  private selectedGameId: number = 0;
  private gameSearch: string = "";
  private games: Array<IGameList> = [];
  private totalGames: number = 0;
  private pages: number = 0;
  private page: number = 1;

  private pageLoading: boolean = false;
  private selectedActionMenu: number = -1;
  private confirmationPopup: boolean = false;
  private confirmationPopupTitle: string = "";
  private confirmationPopupSubtitle: string = "";
  private confirmationPopupText: string = "";
  private cancelBtnText: string = "";
  private confirmBtnText: string = "";
  private portalBaseUrl: string = "";
  private tableHeaders: Array<ITableHeader> = [
    {
      text: "Game Name",
      value: "name",
      sortable: true,
      default: "",
    },
    {
      text: "Start Date",
      value: "startDate",
      sortable: false,
      default: "",
    },
    {
      text: "End Date",
      value: "endDate",
      sortable: false,
      default: "",
    },
    {
      text: "Actions",
      value: "action",
      sortable: false,
      default: "",
    },
  ];
  private inactiveGames: boolean = false;

  get formValidate(): Vue & { validate: () => boolean } {
    return this.$refs.form as Vue & { validate: () => boolean };
  }
  get formReset(): Vue & { reset: () => void } {
    return this.$refs.form as Vue & { reset: () => void };
  }

  public showCommunicationError(err: any) {
    this.snackbarColor = "red";
    this.snackbar = true;
    this.message = window.navigator.onLine
      ? `Some Error occured`
      : `Cannot reach server at the moment`;
  }
  public menuAction(game: IGameList, commandIndex: number) {
    this.selectedGameId = game.id;

    this.selectedActionMenu = commandIndex;
    switch (this.menuItems[commandIndex].Text) {
      case "DEACTIVATE":
        this.confirmationPopupTitle = "Confirm Deactivation";
        this.confirmationPopupSubtitle =
          "Are you sure you wish to deactivate the game?";
        this.confirmationPopupText = "";
        this.cancelBtnText = "Cancel";
        this.confirmBtnText = "Confirm";
        this.confirmationPopup = true;
        break;
      default:
        console.log("Unhandled menuAction");
      case "CLONE":
        let createGameURL: string = `${this.portalBaseUrl}FieldAssistPOC/Games/Create?gameid=${this.selectedGameId}`;
        window.open(createGameURL, "_blank");
    }
  }
  public activateGame(game: IGameList) {
    this.selectedGameId = game.id;
    this.confirmationPopupTitle = "Confirm Activation";
    this.confirmationPopupSubtitle = game.name + " game will be activated.";
    this.confirmationPopupText = "";
    this.cancelBtnText = "Keep Inactive";
    this.confirmBtnText = "Activate";
    this.confirmationPopup = true;
  }
  GetUrl() {
    let that = this;
    ConfugurationService.getPortalBaseUrl()
      .then((res: any) => {
        that.portalBaseUrl = res.data;
      })
      .catch((err: any) => {
        console.log(err);
      });
  }
  public async refreshGamesList() {
    this.inactiveGames
      ? await this.getInactiveGames(this.gameSearch, 1)
      : await this.getGames(this.gameSearch, 1);
  }
  public openCreateTab(value: string): void {
    let createGameURL: string = `${this.portalBaseUrl}FieldAssistPOC/Games/Create`;
    window.open(createGameURL, "_blank");
  }
  public closeSnackbar(value: boolean): void {
    this.snackbar = value;
  }

  public async getGames(searchTerm: string, value: number): Promise<boolean> {
    this.pageLoading = true;
    return new Promise<boolean>((res) => {
      GamificationService.getGames(this.gameSearch, value)
        .then((response) => {
          this.games = response.data.games;
          this.pages = response.data.pages;
          this.totalGames = response.data.total;
          this.pageLoading = false;
          if (this.games.length > 0) {
            this.showGamesList = true;
          } else {
            this.showGamesList = false;
            this.description =
              this.gameSearch.length == 0
                ? "No games Found"
                : "No matching result found";
            this.subdescription =
              this.gameSearch.length == 0
                ? "Please create a game to begin with"
                : "Make sure you typed correctly or try finding in inactive games";
          }
          res(true);
        })
        .catch((error) => {
          this.showCommunicationError(error);
          console.log(error);
          this.pageLoading = false;
        });
    });
  }
  public changePage(value: number): void {
    this.page = value;
    this.inactiveGames
      ? this.getInactiveGames(this.gameSearch, value)
      : this.getGames(this.gameSearch, value);
  }

  public confirmAction(value: any): void {
    if (this.inactiveGames) {
      GamificationService.activateGame(value)
        .then((res) => {
          this.snackbarColor = "success";
          this.message = "Changes Saved Successfully";
          this.actionablePopup();
          this.switchGames();
        })
        .catch((err) => {
          this.showCommunicationError(err);
          console.log(err);
        });
    } else {
      GamificationService.deactivateGame(value)
        .then((res) => {
          this.snackbarColor = "red";
          this.message = "Game deactivated and moved to inactive list";
          this.actionablePopup();
          this.refreshGamesList();
        })
        .catch((err) => {
          this.showCommunicationError(err);
          console.log(err);
        });
    }
  }
  public actionablePopup() {
    this.snackbar = true;
    this.confirmationPopup = false;
    this.showGamesList = true;
  }
  private async GetMenuActions(): Promise<void> {
    this.menuItems = [
      {
        Text: "DEACTIVATE",
        Color: "red",
      },
      {
        Text: "CLONE",
        Color: "red",
      },
    ];
  }

  public async getInactiveGames(
    searchTerm: string,
    page: number
  ): Promise<boolean> {
    this.pageLoading = true;
    return new Promise<boolean>((res) => {
      GamificationService.getInactiveGames(searchTerm, page)
        .then((response) => {
          this.games = response.data.games;
          this.pages = response.data.pages;
          this.totalGames = response.data.total;
          this.pageLoading = false;
          res(true);
        })
        .catch((error) => {
          this.showCommunicationError(error);
          console.log(error);
          this.pageLoading = false;
        });
    });
  }
  public switchGames() {
    if (this.inactiveGames) {
      this.tableHeaders = [
        {
          text: "Game Name",
          value: "name",
          sortable: true,
          default: "",
        },
        {
          text: "Start Date",
          value: "startDate",
          sortable: false,
          default: "",
        },
        {
          text: "End Date",
          value: "endDate",
          sortable: false,
          default: "",
        },
        {
          text: "Actions",
          value: "action",
          sortable: false,
          default: "",
        },
      ];
      this.getInactiveGames(this.gameSearch, 1);
    } else {
      this.tableHeaders = [
        {
          text: "Game Name",
          value: "name",
          sortable: true,
          default: "",
        },
        {
          text: "Start Date",
          value: "startDate",
          sortable: false,
          default: "",
        },
        {
          text: "End Date",
          value: "endDate",
          sortable: false,
          default: "",
        },
        {
          text: "Actions",
          value: "action",
          sortable: false,
          default: "",
        },
      ];
      this.getGames(this.gameSearch, 1);
    }
  }
  async created() {
    this.pageLoading = true;
    this.getGames(this.gameSearch, 1).then((_) => _);
    this.GetMenuActions().then((_) => _);
    this.GetUrl();
  }
}
