var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{attrs:{"fluid":""}},[_c('Navbar',{attrs:{"navbarTitle":"Manage FA Battlegrounds","primaryBtnText":"Create","primaryBtnIcon":"mdi-plus"},on:{"primaryEvent":function($event){_vm.createGame = true;
        _vm.openCreateTab();}},scopedSlots:_vm._u([(_vm.showGamesList)?{key:"secondaryBtnSlot",fn:function(){return [_c('v-switch',{staticClass:"mr-4",attrs:{"label":"Inactive","color":"primary","hide-details":"","dense":""},on:{"change":_vm.switchGames},model:{value:(_vm.inactiveGames),callback:function ($$v) {_vm.inactiveGames=$$v},expression:"inactiveGames"}})]},proxy:true}:null],null,true)}),_c('DataTable',{directives:[{name:"show",rawName:"v-show",value:(_vm.showGamesList || _vm.pageLoading),expression:"showGamesList || pageLoading"}],attrs:{"headers":_vm.tableHeaders,"items":_vm.games,"pageCount":_vm.pages,"totalItems":_vm.totalGames,"page":_vm.page,"loading":_vm.pageLoading},on:{"changePage":_vm.changePage},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var props = ref.props;
        var headers = ref.headers;
return [(headers.value == 'action')?_c('span',[(_vm.inactiveGames)?_c('div',[_c('v-btn',{staticClass:"suboverlineCapsReg primaryFABlue--text",staticStyle:{"border":"1px solid #d8d8d8","border-color":"#d8d8d8 !important"},attrs:{"color":"backgroundDark","height":"32","depressed":""},on:{"click":function($event){return _vm.activateGame(props)}}},[_vm._v(" Activate ")]),_c('v-menu',{attrs:{"offset-y":"","close-on-scroll":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-4",attrs:{"color":"var(--blackMedium)"}},'v-icon',attrs,false),on),[_vm._v(" mdi-dots-horizontal ")])]}}],null,true)},[_c('v-list',{staticClass:"pa-0"},_vm._l((_vm.menuItems),function(menuItem,index){return _c('v-list-item',{key:index,attrs:{"disabled":menuItem.Text === 'DEACTIVATE'},on:{"click":function($event){return _vm.menuAction(props, index)}}},[_c('v-list-item-title',{class:menuItem.Color + "--text suboverlineCapsReg"},[_vm._v(_vm._s(menuItem.Text))])],1)}),1)],1)],1):_c('div',[_c('v-menu',{attrs:{"offset-y":"","close-on-scroll":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-4",attrs:{"color":"var(--blackMedium)"}},'v-icon',attrs,false),on),[_vm._v(" mdi-dots-horizontal ")])]}}],null,true)},[_c('v-list',{staticClass:"pa-0"},_vm._l((_vm.menuItems),function(menuItem,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.menuAction(props, index)}}},[_c('v-list-item-title',{class:menuItem.Color + "--text suboverlineCapsReg"},[_vm._v(_vm._s(menuItem.Text))])],1)}),1)],1)],1)]):_c('span',{class:props[headers.value] == '' ? "blackLow--text" : ""},[_vm._v(" "+_vm._s(props[headers.value] == "" ? headers.default : props[headers.value])+" ")])]}}])}),_c('Illustration',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showGamesList && !_vm.pageLoading),expression:"!showGamesList && !pageLoading"}],attrs:{"description":_vm.description,"subdescription":_vm.subdescription,"illustrationImg":"Binoculars.png"},scopedSlots:_vm._u([{key:"illustrationActionSlot",fn:function(){return [_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.gameSearch.length < 1),expression:"gameSearch.length < 1"}]},[_c('v-divider',{staticClass:"mx-auto w-100px mt-8 highlightGrayBorder"}),_c('div',{staticClass:"text-center mt-8"},[_c('div',{staticClass:"blackMedium--text bodyLt mt-2"},[_vm._v(" Too Many Entries or have a ready excel ")])])],1)]},proxy:true}])})],1),_c('Snackbar',{attrs:{"message":_vm.message,"snackbar":_vm.snackbar,"timeout":5000,"color":_vm.snackbarColor},on:{"update:snackbar":function($event){_vm.snackbar=$event},"closeSnackbar":_vm.closeSnackbar}}),_c('ActionablePopup',{attrs:{"popupDialog":_vm.confirmationPopup,"title":_vm.confirmationPopupTitle,"subtitle":_vm.confirmationPopupSubtitle,"text":_vm.confirmationPopupText,"data":_vm.selectedGameId,"cancelBtnText":_vm.cancelBtnText,"confirmBtnText":_vm.confirmBtnText},on:{"close":function($event){_vm.confirmationPopup = false},"confirm":_vm.confirmAction}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }