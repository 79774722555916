import client from './../apiService';
class ConfigurationService{
    getPortalBaseUrl(){
        const url= `Configuration/GetPortalBaseUrl`;
        return client.get(url,{
            headers:{
                'Content-Type':'application/json'
            }
        })
    }
}
export default new ConfigurationService();