import client from './../apiService';

class GamificationService{
    async getGames(searchTerm: string, page: number){
        debugger;
        const url = `Games/GetGames?searchTerm=${searchTerm}&page=${page}`;
        var result =  await client.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        debugger;
        return result;
    }

    deactivateGame(gameId: number){
        const url = `Games/DeactivateGame?gameId=${gameId}`;
        return client.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }

    activateGame(gameId: number){
        const url = `Games/ActivateGame?gameId=${gameId}`;
        return client.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }

    async getInactiveGames(searchTerm: string, page: number){
        const url = `Games/GetInactiveGames?searchTerm=${searchTerm}&page=${page}`;
        return await client.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

}

export default new GamificationService();